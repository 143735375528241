




import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import Trade from "@/utility/bots/tradeLogEntry";
import moment from "moment";

@Component
export default class DownloadTradeLog extends Vue {
  // 📦 The passed trade log of the bot/bots
  @Prop()
  public tradeLog!: Trade[];

  // 📦 The headers for the CSV file
  private headers = {
    date: "Date (UTC)",
    botId: "Bot Id",
    botType: "Bot Type",
    market: "Market",
    orderType: "Order Type",
    side: "Side",
    price: "Price",
    priceAsset: "Price Asset",
    amount: "Amount",
    amountAsset: "Amount Asset",
    exchangeFee: "Exchange Fee",
    exchangeFeeAsset: "Exchange Fee Asset",
    startingBalance: "Starting Balance",
    startingBalanceAsset: "Starting Balance Asset",
    endingBalance: "Ending Balance",
    endingBalanceAsset: "Ending Balance Asset",
    roi: "Change Since Start %",
    change: "Change Since Last Trade %"
  };

  /**
   * 🔨 Function to assemble CSV lines
   */
  private convertToCSV(objArray: any) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  /**
   * 🔨 Function to export CSV file and allow the user to download it
   */
  private exportCSVFile() {
    let items: any[] = this.tradeLog
      .map((trade: Trade) => {
        return {
          date: moment
            .utc(new Date(trade.date * 1000))
            .format("DD/MM/YYYY HH:mm:ss"),
          botId: trade.botId,
          botType: trade.botName,
          market: trade.market,
          orderType: trade.order_type,
          side: trade.type,
          price: trade.price,
          priceAsset: trade.market.split(":")[1],
          amount: trade.amount,
          amountAsset: trade.currency,
          exchangeFee: trade.fee,
          exchangeFeeAsset: trade.fee_asset,
          startingBalance: trade.startingBotBalance,
          startingBalanceAsset: trade.market.split(":")[1],
          endingBalance: trade.balance,
          endingBalanceAsset: trade.market.split(":")[1],
          roi:
            ((trade.balance - trade.startingBotBalance) /
              trade.startingBotBalance) *
            100,
          change: trade.change ? trade.change : 0
        };
      })
      .reverse();

    if (this.headers) {
      items.unshift(this.headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv: any = this.convertToCSV(jsonObject);

    var exportedFilename =
      "OBC-Trade-Log [" + this.tradeLog[0].botId + "].csv" || "export.csv";

    var blob = new Blob([csv], { type: "text/csv; charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
